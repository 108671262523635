import styled from "styled-components"
import { Translations } from "./Lang"
import { useContext } from "react"
import { LangContext } from "../../App"
import Models from "./Models"

const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: ${params => params.$top};

  box-sizing: border-box;
  padding: 20px 35px;
  border-radius: 15px;

  background: linear-gradient(0deg, rgba(233, 236, 238, 0.83) 0%, rgba(233, 236, 238, 0.83) 100%), url(/home/models_bg.webp);
  background-size: cover;
  background-position: center center;
 

	@media (max-width: 767px) {
		width: 100%;
		box-sizing: border-box;
		padding: 30px 15px;
	}

`

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Title = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 32px;
  color: #133b51;

  > span {
    font-family: 'Gilroy-ExtraBold';
  }
`

const Button = styled.a`
  text-decoration: none;
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #133b51;
  display: flex;
  padding: 15px 29px;
  box-sizing: border-box;
  border: 2px solid #133b51;
  border-radius: 10px;
`

const DesktopButton = styled.div`
	display: block;
	@media (max-width: 767px) {
		display: none;
	}
`

const MobileButton = styled.div`
    display: none;
    @media (max-width: 767px) {
			margin-top: 30px;
			display: flex;
			justify-content: center;
    }
`

const ModelsWrapper = styled.div`
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding: 0px 50px;
  }
`

export default function PromotedModelsSection ({ top })
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper $top={top}>
      <Top>
        <Title>
          {Translations[lang].title}
        </Title>
        <DesktopButton>
	        <Button href="/katalog-jachtow">
		        {Translations[lang].btn}
	        </Button>
        </DesktopButton>
      </Top>
      <ModelsWrapper>
        <Models />
      </ModelsWrapper>
	    <MobileButton>
		    <Button href="/katalog-jachtow">
			    {Translations[lang].btn}
		    </Button>
	    </MobileButton>
    </Wrapper>
  )
}