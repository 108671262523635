/**
 * Oferta Kursów Sekcja
 */

import styled from "styled-components"
import { Translations } from "./Lang";
import { useContext } from "react";
import { LangContext } from "../../App";


const Wrapper = styled.div`
  width: 1320px;
  margin: auto;
  margin-top: ${params => params.$top};
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px -20px 70px 0px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  background: linear-gradient(0deg, rgba(148, 7, 58, 0.80) 0%, rgba(148, 7, 58, 0.80) 100%), url(/home/courses_bg.webp);
  background-size: cover;
  background-position: center center;
  box-sizing: border-box;
  padding: 30px;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 35px;
  }
`

const CenterTitle = styled.div`
  margin-top: -80px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 32px;
  color: #fff;

  display: flex;
  align-items: flex-end;
  gap: 15px;

  span {
    font-family: 'Gilroy-ExtraBold';
  }

  @media (max-width: 767px) {
    font-size: 24px;
    align-items: center;
    margin-top: 0px;
  }
`

const CenterDescr = styled.div`
  width: 543px;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  
  @media (max-width: 767px) {
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Cards = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 0px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    gap: 19px;
    height: 420px;
    box-sizing: border-box;
    padding: 20px 15px;
    scroll-snap-align: center;
  }
`

const Card = styled.a`
  text-decoration: none;
  width: 291px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    flex-shrink: 0;
    height: fit-content;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
`

const CardThumb = styled.div`
  height: 182px;
  background-image: url(/courses/${props => props.$img});
  background-size: cover;
  background-position: center center;
  border-radius: 10px 10px 0px 0px;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  box-sizing: border-box;
  padding: 12px 0px 17px 0px;
  align-items: center;
`

const CardTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 14px;
  color: #133b51;
  text-transform: uppercase;
  text-align: center;
`

const CardProps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`

const CardProp = styled.div`
  font-family: 'Gilroy-Light';
  font-weight: 400;
  font-size: 14px;
  color: #133b51;
  text-transform: uppercase;

  > span { 
    font-family: 'Gilroy-ExtraBold';
  }
`

const CardButton = styled.button`
  border-radius: 5px;
  background-color: #94073a;
  box-sizing: border-box;
  padding: 11px 36px;
  border: none;
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
`

const CenteredButton = styled.a`
  text-decoration: none;
  margin-top: 30px;
  border-radius: 10px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 15px 22px;
  border: 2px solid #fff;
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
`


const Logo = styled.div`

  background-color: #fff;
  border-radius: 0px 20px 20px 0px;
  width: 197px;
  box-sizing: border-box;
  padding: 18px 25px 18px 53px;
  align-self: flex-start;
  translate: -30px;

  > img {
    width: 120px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const LogoMobile = styled.div`
  display: none;
  background-color: #fff;
  border-radius: 0px 20px 20px 0px;
  width: 197px;
  box-sizing: border-box;
  padding: 18px 25px 18px 53px;
  align-self: flex-start;
  translate: -30px;

  > img {
    width: 120px;
  }

  @media (max-width: 767px) {
    display: block;
  }
`


export default function CoursesSection ({ top })
{
  const { lang } = useContext(LangContext);

  return (
    <Wrapper $top={top}>
      <Logo>
        <img src="/menu/academy.svg" />
      </Logo>
      <CenterTitle>
        <LogoMobile>
          <img src="/menu/academy.svg" />
        </LogoMobile>
        <div>
          {Translations[lang].title}
        </div>
      </CenterTitle>
      <CenterDescr>
        {Translations[lang].descr}
      </CenterDescr>
      <Cards>
        {Translations[lang].list.map((course, key) => (
          <Card key={key} href={course.href}>
            <CardThumb $img={course.img} />
            <CardContent>
              <CardTitle>
                {course.title}
              </CardTitle>
              <CardProps>
                <CardProp>
                  {Translations[lang].loc}:&nbsp;
                  <span>
                    {course.loc}
                  </span>
                </CardProp>
                <CardProp>
                  {Translations[lang].price}:&nbsp;
                  <span>
                    {course.price}
                  </span>
                </CardProp>
              </CardProps>
              <CardButton>
                {Translations[lang].checkOut}
              </CardButton>
            </CardContent>
          </Card>
        ))}
      </Cards>
      <CenteredButton href="/academy">
        {Translations[lang].goToOffer}
      </CenteredButton>
    </Wrapper>
  )
}