
// const __server = "http://127.0.0.1:8080";
const __server = "https://api.repulse-app.pl";
export default __server;

/**
 * WARN: Enigmatyczna migracja plików na serwer lokalny
 *       z google cloudsów, so:
 */
export const __gcloud = `${__server}/storage/uploads`;

export const __countries = [ "HR", "GR", "IT", "SC", "PL" ];

export const __countires_with_bundles = [ "HR", "GR" ];

export const __captcha_key = "6LemdsIqAAAAADcjuWw02aQUV5Q_toJyjEpdQ7D9";

export const __booking_status = {
    "CANCELED": -1,
    "AWAITING_PAYMENT": 1,
    "AWAITING_SECOND_PAYMENT": 2,
    "PAID": 3,
    "BOOKED": 4,
    "FINALIZED": 5
}

export const __bundles = {
    standard: "Standard",
    comfort: "Comfort",
    prestige: "Prestige",
    all_inclusive: "All Inclusive"
};