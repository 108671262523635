
import { useContext } from "react";
import { Link } from "../Abstract/MenuComponents";
import { Translations } from "./Lang";
import { LangContext } from "../../../App";
import Menu from "../Abstract/Menu";

import { IconBlock, IconBlockImage, IconBlockLink } from "../Components/MenuStripe";
import { MenuStripeLinkMail, MenuStripeLinkPhone } from "../Abstract/MenuStripeLink";
import MenuStripeSocials from "../Abstract/MenuStripeSocials";

export default function LiteMenu ()
{
  const { lang } = useContext(LangContext);

  return (
    <Menu
      disabledMiddleSection={true}
      backgroundImage="/menu/bg-static.svg"
      navigation={<>
        <Link href="/katalog-jachtow">{Translations[lang].home.yachtsCatalogue}</Link>
        <Link href="/nasze-destynacje">{Translations[lang].home.ourDestinations}</Link>
        <Link href="/o-nas">{Translations[lang].home.aboutUs}</Link>
        <Link href="/blog">{Translations[lang].home.blog}</Link>
      </>}

      stripeItems={
        <>
          <MenuStripeLinkPhone tel="+48 887 234 362" />
          <MenuStripeLinkMail mail="biuro@forsail.pl" />
          <MenuStripeSocials />
        </>
      }
    />
  )
}