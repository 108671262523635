import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { LangContext } from "../../App"


import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import { Translations } from "./Lang";

import axios from 'axios';
import __server from '../../Api';


const Wrapper = styled.div`
  margin-top: 60px;
  font-family: 'Gilroy-Light';
  font-weight: 400;
  color: #133b51;

  @media (max-width: 767px) {
    box-sizing: border-box;
    padding: 0px 15px;
  }
`

const Title = styled.div`
  font-size: 32px;
  > span {
    font-family: 'Gilroy-ExtraBold';
    color: #32c8d2;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`

const Descr = styled.div`
  margin-top: 20px;
  width: 910px;
  font-size: 20px;

  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
  }
`

const Double = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

const Columns = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;

  @media (max-width: 767px) {
    gap: 17px;
    box-sizing: border-box;
    padding: 0px 50px;
  }
`

const Map = styled.div`
  margin-top: -30px;
  width: 100%;
  height: 470px;
  background-image: url(/home/maps.webp);
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    margin-top: 30px;
    justify-content: flex-end;
    align-items: center;
  }
`

const Country = styled.a`
  text-decoration: none;
  font-size: 24px;
  font-family: 'Gilroy-ExtraBold';
  color: #133b51;
  width: fit-content;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`

const Card = styled.div`
  font-family: 'Gilroy-Light';
  color: #133b51;
  font-size: 16px;
  width: 310px;
  box-sizing: border-box;
  padding: 20px 25px;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.15);

  > span {
    font-family: 'Gilroy-ExtraBold';
  }

  &:first-child {
    translate: 0px -50px;
    align-self: flex-end;
  }

  &:last-child {
    translate: 0px 50px;
  }

  @media (max-width: 767px) {
    display: block;
    width: 80%;

    &:first-child {
      translate: 0px 50px;
      align-self: center;
    }

    &:last-child {
      margin-top: 20px;
    }
  }
`

export default function Directions ({})
{
  const { lang } = useContext(LangContext);


  countries.registerLocale(plLocale);
  countries.registerLocale(enLocale);
  countries.registerLocale(deLocale);

  const [ data, setData ] = useState(null);

  useEffect(() => {
    axios.get(`${__server}/front/pages/about-us`).then((resp) => {
      setData(resp.data);
    }).catch((err) => {
      console.log('[failed to fetch misc data]', err);
    })
  }, []);

  const date = new Date();
  date.setDate(date.getDate() + 1);
  const searchDate = new Date(date);
  searchDate.setDate(date.getDate() + ((6 - date.getDay() + 7) % 7 || 7));

  return (
    <Wrapper>
      <Title>
        {Translations[lang].directions.title}
      </Title>
      <Descr>
        {Translations[lang].directions.descr}
      </Descr>
      <Double>
        <Columns>
          {data && data.map((country, key) => (
            <Country key={key} href={`/oferty?start=${searchDate.toISOString().split('T')[0]}&days=7&country=${countries.alpha3ToAlpha2(country.country)}&heads=2&bundle=standard`}>
              {countries.getName(country.country, lang)}
            </Country>
          ))}
        </Columns>
        <Map>
          <Card>
            {Translations[lang].directions.card1}
          </Card>
          <Card>
            {Translations[lang].directions.card2}
          </Card>
        </Map>
      </Double>
    </Wrapper>
  )
}