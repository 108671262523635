
export const Translations = {
    pl: {
        success: "Płatność powiodła się",
        failure: "Płatność się nie udała",
        successDescr: "Przejdź do szczegółów rezerwacji, klikając w przycisk poniżej",
        failureDescr: "Spróbuj ponownie, w razie problemów skontaktuj się z nami",
        detailsButton: "Szczegóły rezerwacji",

        veryfication: "Trwa weryfikacja płatności",
        pleaseWait: "Ta operacja jest automatyczna, prosimy o cierpliwość"

    }
}


Translations.en = Translations.pl;
Translations.de = Translations.pl;