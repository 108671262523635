
export const Translations = {
  pl: {
    home: {
      title: 'Odkrywaj świat na pokładach luksusowych jachtów z FORSAIL ',
      descr: 'Oferujemy czartery jachtów w najpiękniejszych zakątkach Europy i świata: Morze Śródziemne, Karaiby, Seszele, Tajlandia – malownicze krajobrazy, komfortowe warunki i niezapomniane wspomnienia gwarantowane!',
      yachtCharter: "Czarter jachtów",
      yachtsCatalogue: "Katalog Jachtów",
      ourDestinations: "Nasze Destynacje",
      blog: 'Blog',
      aboutUs: 'O nas',
    },
    camp: {
      sportSummerCamps: "Półkolonie Sportowo Żeglarskie",
      summerCamps: "Półkolonie Żeglarskie",
      tutorCamp: "Obóz Szkoleniowy",
      sailorsCamp: "Obozy Żeglarskie"
    },
    academy: {
      yachtHelmsman: "Jachtowy Sternik Morski",
      yachtSailor: "Żeglarz Jachtowy",
      additionalCurses: "Kursy Doszkalające",
      internCruises: "Rejsy Stażowe",
      testBase: "Baza testów"
    },
    incentive: {
      companyCruises: "Rejsy firmowe",
      companyRegattas: "Regaty firmowe",
      companyCharters: "Czartery dla firm",
      managementCurses: "Szkolenia menadżerskie",
      specialEvents: "Eventy specjalne"
    },
    yachtCharter: {
      title: "Lorem Ipsum",
      descr: "Dolor sit amet",
      skippers: "Skiperzy"
    }
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;