import styled from "styled-components";
import MenuLangSwitcher from "./MenuLangSwitcher";
import MenuButtons from "./MenuButtons";
import {useContext} from "react";
import {ThemeContext} from "../../../App";

import { motion } from 'framer-motion';

const __menu_default_height = 640;

const Wrapper = styled.div`
	display: none;
	width: 100%;
	background-color: ${params => params.$theme.mobileMenuBackground};
	box-sizing: border-box;
	padding: 120px 18px 45px 18px;
	height: ${__menu_default_height}px;
	@media (max-width: 767px) {
		display: block;
	}
`

const Bar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Nav = styled.div`
	margin-top: 27px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 30px;
`

const LangWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

const CloseButton = styled.button`
	outline: none;
	background-color: transparent;
	border: none;
	
	> img {
		width: 20px;
	}
`

const Buttons = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 130px;
`

export default function MobileMenu ({ navigation, isOpen, setIsOpen })
{
	const theme = useContext(ThemeContext);

	return (
		<motion.div
			style={{
				position: 'fixed',
				top: -1 * __menu_default_height,
				left: 0,
				width: '100%',
				zIndex: 999
			}}
			variants={{
				up: { top: -1 * __menu_default_height },
				down: { top: -100 }
			}}
			animate={isOpen ? 'down' : 'up'}

			transition={{ type: 'spring', stiffness: 70 }}
		>
			<Wrapper $theme={theme}>
				<Bar>
					<LangWrapper>
						<MenuLangSwitcher />
					</LangWrapper>
					<CloseButton onClick={(e) => setIsOpen(false)}>
						<img src="/ico/close-menu.svg" />
					</CloseButton>
				</Bar>
				<Nav>
					{navigation}
					<Buttons>
						<MenuButtons />
					</Buttons>
				</Nav>
			</Wrapper>
		</motion.div>
	)
}