import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Translations } from "../Lang"
import { LangContext } from "../../../App"
import { Heading } from "../MyData/MyData"
import axios from "axios"
import __server from "../../../Api"
import { formatNumberByCountry } from "../../../utils/number-formatter"

const Wrapper = styled.div`
    width: 1320px;

    @media (max-width: 767px) {
        width: 100%;
    }
`

const Title = styled.div`
    font-family: 'Gilroy-ExtraBold';
    font-size: 32px;
    font-weight: 400;
    color: #133b51;

    @media (max-width: 767px) {
        font-size: 24px;
    }
`

const Table = styled.div`
    margin-top: 25px;
    width: 100%;

    @media (max-width: 767px) {
        display: none;
    }
`

const Head = styled.div`
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
    padding: 25px 30px;

    display: grid;
    grid-template-columns: 0.4fr 0.7fr 1.4fr 1.4fr 1.4fr 0.6fr 1fr;
    gap: 10px;
`

const HeadItem = styled.div`
    text-align: center;
    font-family: 'Gilroy-Light';
    font-size: 16px;
    font-weight: 400;
    color: #133b51;
    box-sizing: border-box;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
`

const Row = styled.div`
    box-sizing: border-box;
    padding: 30px;

    display: grid;
    grid-template-columns: 0.4fr 0.7fr 1.4fr 1.4fr 1.4fr 0.6fr 1fr;
    gap: 10px;
    border-bottom: 1px solid #133b51;
`

const Item = styled.div`
    box-sizing: border-box;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    font-family: 'Gilroy-ExtraBold';
    font-size: 16px;
    font-weight: 400;
    color: #133b51;

    > div {
        font-size: 14px;
    }
`

const Icon = styled.a`
    > img {
        width: 33px;
        height: 33px;
        object-fit: contain;
        cursor: pointer;
    }
`

const MobileList = styled.div`
    display: none;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 35px;
    gap: 20px;

    @media (max-width: 767px) {
        display: flex;
    }
`

const MobileBooking = styled.div`
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 25px;
`

const MobileProp = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: 'Gilroy-Light';
    font-size: 16px;
    font-weight: 400;
    color: #133b51;
    gap: 20px;

    > div:last-child {
        font-family: 'Gilroy-ExtraBold';
        text-align: right;
        font-size: 14px;
        
    }
`

/**
 * Tak zejebiście przygotowane że trzeba renderować dwie wersje,
 * tabelkę i wersję dla mobilki xD
 */
export const Booking = function ({})
{
    const { lang } = useContext(LangContext);

    const [list, setList] = useState([]);

    useEffect(() => {
        axios.get(`${__server}/front/booking`).then((resp) => {
            setList(resp.data);
        }).catch((err) => {
            console.error(err);
        })
    }, []);

    return (
        <Wrapper>
            <Title>
                {Translations[lang].myBookingPage.list}
            </Title>

            <Table>
                <Head>
                    <HeadItem>
                        {Translations[lang].myBookingPage.id}
                    </HeadItem>
                    <HeadItem>
                        {Translations[lang].myBookingPage.kind}
                    </HeadItem>
                    <HeadItem>
                        {Translations[lang].myBookingPage.start}
                    </HeadItem>
                    <HeadItem>
                        {Translations[lang].myBookingPage.end}
                    </HeadItem>
                    <HeadItem>
                        {Translations[lang].myBookingPage.status}
                    </HeadItem>
                    <HeadItem>
                        {Translations[lang].myBookingPage.price}
                    </HeadItem>
                    <HeadItem>
                        {Translations[lang].myBookingPage.options}
                    </HeadItem>
                </Head>
                <Body>
                    {list.map((item, key) => (
                        <Row key={key}>
                            <Item>
                                {item.id}
                            </Item>
                            <Item>
                                {item.kind}
                            </Item>
                            <Item>
                                <div>
                                    {item.startBase}
                                </div>
                                {item.startDate.substr(0, item.startDate.length - 3)}
                            </Item>
                            <Item>
                                <div>
                                    {item.endBase}
                                </div>
                                {item.endDate.substr(0, item.endDate.length - 3)}
                            </Item>
                            <Item>
                                {Translations[lang].myBookingPage.statuses[item.status]}
                            </Item>
                            <Item>
                                {formatNumberByCountry(item.totalPrice, lang, 2)} EUR
                            </Item>
                            <Item>
                                <Icon href={`/rezerwacja/${item.id}`}>
                                    <img src="/ico/eye.svg" />
                                </Icon>
                            </Item>
                        </Row>
                    ))}
                </Body>
            </Table>
            <MobileList>
                {list.map((item, key) => (
                    <MobileBooking key={key}>
                        <MobileProp>
                            {Translations[lang].myBookingPage.id}
                            <div>
                                {item.id}
                            </div>
                        </MobileProp>
                        <MobileProp>
                            {Translations[lang].myBookingPage.kind}
                            <div>
                                {item.kind}
                            </div>
                        </MobileProp>
                        <MobileProp>
                            {Translations[lang].myBookingPage.start}
                            <div>
                                {item.startBase} <br/>
                                {item.startDate.substr(0, item.startDate.length - 3)}
                            </div>
                        </MobileProp>
                        <MobileProp>
                            {Translations[lang].myBookingPage.end}
                            <div>
                                {item.endBase} <br/>
                                {item.endDate.substr(0, item.endDate.length - 3)}
                            </div>
                        </MobileProp>
                        <MobileProp>
                            {Translations[lang].myBookingPage.status}
                            <div>
                                {Translations[lang].myBookingPage.statuses[item.status]}
                            </div>
                        </MobileProp>
                        <MobileProp>
                            {Translations[lang].myBookingPage.price}
                            <div>
                                {formatNumberByCountry(item.totalPrice, lang, 2)} EUR
                            </div>
                        </MobileProp>
                        <MobileProp>
                            {Translations[lang].myBookingPage.options}
                            <div>
                                <Icon href={`/rezerwacja/${item.id}`}>
                                    <img src="/ico/eye.svg" />
                                </Icon>
                            </div>
                        </MobileProp>
                    </MobileBooking>
                ))}
            </MobileList>
        </Wrapper>
    )
}