
export const Translations = {
    pl: {
        bookingInfo: "Informacje o rezerwacji",
        personalData: "Dane osobowe",
        kind: "Typ jachtu",
        year: "Rocznik jachtu",
        cabins: "Liczba koi",
        startBase: "Początkowy port",
        startDate: "Data rozpoczęcia",
        endBase: "Końcowy port",
        endDate: "Data zakończenia",
        totalPrice: "Cena charteru",
        name: "Imię",
        surname: "Nazwisko",
        serial: "Seria i numer dowodu",
        companyName: "Nazwa firmy (opcjonalne)",
        nip: "NIP (opcjonalne)",
        address: "Adres",
        street: "Nazwa ulicy",
        house: "Numer budynku",
        flat: "Numer lokalu (opcjonalne)",
        country: "Kraj",
        postCode: "Kod pocztowy",
        city: "Miasto",
        contactData: "Dane kontaktowe",
        mail: "Adres e-mail",
        tel: "Numer telefonu",
        submit: "WYŚLIJ ZGŁOSZENIE",
        tos: "Lorem ipsum dolor sit amet, consecteur, lux lucreat octo porta serpenta.",
        obligatoryPayments: "Opłaty obowiązkowe",
        administrativePayments: "Opłaty administracyjne",
        deposit: "Kaucja",
        bundle: "Pakiet charterowy",
        extraPayments: "Opłaty dodatkowe",
        heads: "Liczba osób",
        reservationNumber: "Numer rezerwacji",
        createdAt: "Data utworzenia",
        status: "Status rezerwacji",
        yachtName: "Nazwa jachtu",
        model: "Model",
        bmId: "ID rezerwacji",
        bmCode: "Kod rezerwacji",
        firstPaymentSummary: "Pierwsza rata",
        secondPaymentSummary: "Druga rata",

        thanksForRegistration: "Szczegóły rezerwacji",
        summary: "Podsumowanie kosztów",
        sincereThankYou: "Serdecznie dziękujemy za złożenie rezerwacji w naszym serwisie.",

        pleasePay: <>
            Prosimy o dokonanie przelewu na poniższe konto: <br/><br/>
            Dane do przelewu: <br/><br/>

            Nazwa firmy: <br/>
            Adres firmy: <br/>
            NIP: <br/>
            Tytułem: numer rezerwacji
        </>,

        payOnline: "Istnieje również możliwość dokonania przelewu za pomocą szybkich metod płatności.",

        firstPayment: "ZAPŁAĆ I RATĘ",
        finalPayment: "ZAPŁAĆ II RATĘ",
        fullPayment: "ZAPŁAĆ W STRIPE",

        downloadContract: "POBIERZ UMOWĘ",

        charterPrice: "Cena Czarteru",
        depositPrice: "Cena Depozytu",
        bundlePrice: "Pakiet Czarterowy",
        extrasPrice: "Opłaty Dodatkowe",
        sumup: "Podsumowanie",

        statuses: {
            "-1": "Odrzucono",
            1: "Wymaga płatności",
            2: "Wymaga płatności (druga rata)",
            3: "Opłacone",
            4: "Zarezerwowane",
            5: "Zakończone",
        }

    }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;