import { useContext } from "react";
import { Link, Title } from "../Abstract/MenuComponents";
import { Translations } from "./Lang";
import { LangContext } from "../../../App";

import Menu from "../Abstract/Menu";

import { MenuStripeLinkMail, MenuStripeLinkPhone } from "../Abstract/MenuStripeLink";
import MenuStripeSocials from "../Abstract/MenuStripeSocials";

export default function PageMenu ({ title, backgroundOverwrite })
{
  const { lang } = useContext(LangContext);

  return (
    <Menu 
      backgroundOverwrite={backgroundOverwrite}
      navigation={<>
        <Link href="/katalog-jachtow">{Translations[lang].home.yachtsCatalogue}</Link>
        <Link href="/nasze-destynacje">{Translations[lang].home.ourDestinations}</Link>
        <Link href="/o-nas">{Translations[lang].home.aboutUs}</Link>
        <Link href="/blog">{Translations[lang].home.blog}</Link>
      </>}

      middleSection={
        <Title>{title}</Title>
      }

      stripeItems={
        <>
          <MenuStripeLinkPhone tel="+48 887 234 362" />
          <MenuStripeLinkMail mail="biuro@forsail.pl" />
          <MenuStripeSocials />
        </>
      }

    />
  )
}