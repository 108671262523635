import './App.css';
import axios, {create} from 'axios';

import { createContext, useEffect, useState } from 'react';

import {Route, Routes} from "react-router-dom";

import HomePage from './Pages/Home/HomePage';
import BlogPage from './Pages/Blog/List/BlogPage';
import BlogSinglePage from './Pages/Blog/Page/BlogSinglePage';
import ContactPage from './Pages/Contact/ContactPage';
import FaqPage from './Pages/Faq/FaqPage';
import AboutUsPage from './Pages/AboutUs/AboutUsPage';
import AuthPage from './Pages/Auth/AuthPage';
import { AuthMode } from './Pages/Auth/AuthPage';
import { academyTheme, campTheme, incentiveTheme, mainTheme, yachCharterTheme } from './ThemeProvider';
import CampPage from './Pages/Subpages/Camp/Home/CampPage';
import AcademyPage from './Pages/Subpages/Academy/HomePage/AcademyPage';
import PolkolonieSportowoZeglarskiePage from './Pages/Subpages/Camp/PolkolonieSportowoZeglarskie/PolkolonieSportowoZeglarskiePage';
import ObozyZeglarskiePage from './Pages/Subpages/Camp/ObozyZeglarskie/ObozyZeglarskiePage';
import SzkolenieSternikMorskiPage from './Pages/Subpages/Academy/SzkolenieSternikMorski/SzkolenieSternikMorskiPage';
import YachtCharterPage from './Pages/Subpages/YachtCharter/Home/YachtCharterPage';
import IncentiveHomePage from './Pages/Subpages/Incentive/Home/IncentiveHomePage';
import CompanyRegattasPage from './Pages/Subpages/Incentive/CompanyRegattas/CompanyRegattasPage';
import CompanyChartersPage from './Pages/Subpages/Incentive/CompanyCharters/CompanyChartersPage';
import ManagementCursesPage from './Pages/Subpages/Incentive/ManagementCurses/ManagementCursesPage';
import SpecialEventsPage from './Pages/Subpages/Incentive/SpecialEvents/SpecialEventsPage';
import CompanyCruisesPage from './Pages/Subpages/Incentive/CompanyCruises/CompanyCruisesPage';
import OurDestinationsPage from './Pages/OurDestinations/OurDestinationsPage';
import CountryPage from './Pages/OurDestinations/CountryPage';
import ModelsPage from './Pages/Models/ModelsPage';
import YachtsPage from './Pages/Yacht/List/YachtsPage';
import YachtPage from './Pages/Yacht/View/YachtPage';
import PolkolonieZeglarskiePage from "./Pages/Subpages/Camp/PolkolonieZeglarskie/PolkolonieZeglarskiePage";
import ObozSzkoleniowyPage from "./Pages/Subpages/Camp/ObozSzkoleniowy/ObozSzkoleniowyPage";
import SkippersPage from "./Pages/Skippers/SkippersPage";
import OfertyPage from './Pages/Oferty/OfertyPage';
import OfertaPage from './Pages/Oferta/OfertaPage';
import ProfilePage from './Pages/Profile/ProfilePage';
import NotFoundPage from './Pages/Error/NotFoundPage';
import CityPage from './Pages/OurDestinations/City/CityPage';
import BookingPage from './Pages/Booking/BookingPage';
import BookingDetailsPage from './Pages/Booking/BookingDetailsPage';
import ConfirmationPage from './Pages/Payments/ConfirmationPage';
import SuccessfullPaymentPage from './Pages/Payments/SuccessfullPaymentPage';
import FailedPaymentPage from './Pages/Payments/FailedPaymentPage';

export const LangContext = createContext();
export const ThemeContext = createContext();

function App()
{
  const [lang, setLang] = useState('pl');

  axios.defaults.withCredentials = true;
  useEffect(() => {
    const savedLang = localStorage.getItem('forsail_lang');
    if (savedLang != null) {
      setLang(savedLang);
    } else {
      /** Auto system lang */
      const langs = ['en', 'pl', 'de'];
      const sysLang = navigator.language.split('-').at(0);
      if (langs.includes(sysLang)) {
        setLang(sysLang);
        localStorage.setItem('forsail_lang', sysLang);
      }
    }

  }, []);

  const token = localStorage.getItem("bearer_token");
  if (token != null) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return (
    <div className="App">
      <LangContext.Provider value={{lang, setLang}}>
        <ThemeContext.Provider value={mainTheme}>
          <Routes>
            <Route path="/" Component={(e) => <HomePage/>} />

            <Route path="/potwierdzenie-platnosci/:stripePaymentIntent/:bookingId" Component={ConfirmationPage} />
            <Route path="/platnosc-pomyslna/:bookingId" Component={SuccessfullPaymentPage} />
            <Route path="/blad-platnosci/:bookingId" Component={FailedPaymentPage} />

            <Route path="/rezerwacja" Component={BookingPage} />
            <Route path="/rezerwacja/:bookingId" Component={BookingDetailsPage} />

            <Route path="/profil" Component={(e) => <ProfilePage/>} />
            <Route path="/kontakt" Component={ContactPage} />

            <Route path="/blog" Component={BlogPage} />
            <Route path="/blog/:slug" Component={BlogSinglePage} />

            <Route path="/faq" Component={FaqPage} />
            <Route path="/o-nas" Component={AboutUsPage} />

            <Route path="/nasze-destynacje" Component={OurDestinationsPage} />
            <Route path="/nasze-destynacje/:country" Component={CountryPage} />
            <Route path="/nasze-destynacje/:country/:city" Component={CityPage} />

            <Route path="/katalog-jachtow" Component={ModelsPage} />
            <Route path="/katalog-jachtow/:slug" Component={YachtsPage} />
            <Route path="/katalog-jachtow/:model/:id" Component={YachtPage} />

            <Route path="/skipperzy" Component={SkippersPage} />

            <Route path="/oferty" Component={OfertyPage} />
            <Route path="/oferta/:id" Component={OfertaPage} />

            <Route path="/logowanie" Component={() => <AuthPage authMode={AuthMode.Login} />} />
            <Route path="/rejestracja" Component={() => <AuthPage authMode={AuthMode.Register} />} />
            <Route path="/resetowanie-hasla" Component={() => <AuthPage authMode={AuthMode.Reset} />} />
            <Route path="/aktywacja/:mail" Component={() => <AuthPage authMode={AuthMode.Activate} />} />

            <Route path="/404" Component={() => <NotFoundPage />} />
          </Routes>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={campTheme}>
          <Routes>
            <Route path="/camp" Component={() => <CampPage />} />
            <Route path="/camp/polkolonie-sportowo-zeglarskie" Component={() => <PolkolonieSportowoZeglarskiePage />} />
            <Route path="/camp/polkolonie-zeglarskie" Component={() => <PolkolonieZeglarskiePage />} />
            <Route path="/camp/obozy-zeglarskie" Component={() => <ObozyZeglarskiePage />} />
            <Route path="/camp/oboz-szkoleniowy" Component={() => <ObozSzkoleniowyPage />} />
          </Routes>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={academyTheme}>
          <Routes>
            <Route path="/academy" Component={() => <AcademyPage />} />
            <Route path="/academy/szkolenie-jachtowy-sternik-morski" Component={() => <SzkolenieSternikMorskiPage />} />
          </Routes>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={yachCharterTheme}>
          <Routes>
            <Route path="/yacht-charter" Component={() => <YachtCharterPage />} />
          </Routes>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={incentiveTheme}>
          <Routes>
            <Route path="/incentive" Component={() => <IncentiveHomePage />} />
            <Route path="/incentive/regaty-firmowe" Component={() => <CompanyRegattasPage />} />
            <Route path="/incentive/czartery-dla-firm" Component={() => <CompanyChartersPage />} />
            <Route path="/incentive/szkolenia-menadzerskie" Component={() => <ManagementCursesPage />} />
            <Route path="/incentive/eventy-specjalne" Component={() => <SpecialEventsPage />} />
            <Route path="/incentive/rejsy-firmowe" Component={() => <CompanyCruisesPage />} />
          </Routes>
        </ThemeContext.Provider>
      </LangContext.Provider>
    </div>
  );
}

export default App;
