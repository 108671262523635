import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import axios from "axios";
import __server, { __booking_status } from "../../Api";
import { formatNumberByCountry } from "../../utils/number-formatter";


const Wrapper = styled.div`
    width: 1320px;
    margin: auto;
    margin-top: 50px;
    display: flex;
    gap: 50px;

    @media (max-width: 767px) {
        width: 100%;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: 35px;
        padding: 0px 15px;
        gap: 30px;
    }
`

const Waiting = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9999;    
    cursor: wait;
`

const Left = styled.div`
    width: 65%;

    @media (max-width: 767px) {
        width: 100%;
    }
`

const Right = styled.div`
    width: 35%;

    @media (max-width: 767px) {
        width: 100%;
    }
`

const Bar = styled.div`
    width: 1px;
    background-color: #133b51;    
`

const Title = styled.div`
    font-family: 'Gilroy-ExtraBold';
    font-weight: 400;
    font-size: 32px;
    color: #133b51;

    @media (max-width: 767px) {
        font-size: 24px;
    }
`

const Descr = styled.div`
    margin-top: 20px;
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;

    @media (max-width: 767px) {
        margin-top: 20px;
    }
`

const Button = styled.button`
    width: 100%;
    display: block;
    align-self: flex-end;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: #133b51;
    box-sizing: border-box;
    font-family: 'Gilroy-ExtraBold';
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding: 14px 32px;

    &:disabled {
        cursor: not-allowed;
        background-color: #cfcfcf;
    }
`

const Summary = styled.div`
    margin-top: 50px;
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
    }
`

const Pricing = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;

    > div {
        text-align: right;
    }

    @media (max-width: 767px) {
        font-size: 18px;
    }
`

const Spacer = styled.div`
    width: 100%;
    height: 1px;
    background-color: #133b514c;
`

const Buttons = styled.div`
    width: fit-content;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Row = styled.div`
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;

    font-family: 'Gilroy-ExtraBold';
    font-weight: 400;
    font-size: 16px;
    color: #133b51;
    border-radius: 4px;

    > div {
        font-family: 'Gilroy-Light';
    }

    &:nth-child(2n - 1) {
        background-color: #ddf5f8;
    }
`


const Receipt = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 80%;

    @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
    }

`

export default function BookingDetailsPage ()
{
    const { bookingId } = useParams();
    const { lang } = useContext(LangContext);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${__server}/front/booking/${bookingId}`).then((resp) => {
            console.log(resp.data);
            setData(resp.data);
            setIsLoading(false);
        }).catch((err) => {
            window.location.href = '/profil';
        })
    }, []);

    if (!data) {
        return <></>
    }

    const onPaymentRequest = function (e)
    {
        setIsLoading(true);
        axios.get(`${__server}/front/payment/${bookingId}`).then((resp) => {
            window.location.href = resp.data.url;
        }).catch((err) => {
            console.error(err);
        })
    }

    const onContractDownload = function (e)
    {
        setIsLoading(true);
        axios({
            url: `${__server}/front/contract/${bookingId}`,
            method: "GET",
            responseType: "blob"
        }).then((resp) => {
            const blobUrl = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', 'Umowa Forsail.pdf'); 
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setIsLoading(false);
        }).catch((err) => {

        });
    }

    return (
        <>
            <LiteMenu />
            {isLoading && <Waiting />}
            <Wrapper>
                <Left>
                    <Title>
                        {Translations[lang].thanksForRegistration}
                    </Title>
                    <Descr>
                        {Translations[lang].sincereThankYou}
                    </Descr>

                    <Receipt>
                        <Row>
                            {Translations[lang].reservationNumber}
                            <div>
                                {data.id}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].createdAt}
                            <div>
                                {data.created_at.split('T').at(0)}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].status}
                            <div>
                                {Translations[lang].statuses[data.status]}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].heads}
                            <div>
                                {data.heads}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].kind}
                            <div>
                                {data.kind}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].yachtName}
                            <div>
                                {data.yacht}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].model}
                            <div>
                                {data.model}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].year}
                            <div>
                                {data.year}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].cabins}
                            <div>
                                {data.cabins}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].startDate}
                            <div>
                                {data.startDate.substr(0, data.startDate.length - 3)}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].startBase}
                            <div>
                                {data.startBase}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].endDate}
                            <div>
                                {data.endDate.substr(0, data.endDate.length - 3)}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].endBase}
                            <div>
                                {data.endBase}
                            </div>
                        </Row>
                        <Row>
                            {Translations[lang].bundle}
                            <div>
                                {data.bundle}
                            </div>
                        </Row>
                        {data.bmId != null && (
                            <Row>
                                {Translations[lang].bmId}
                                <div>
                                    {data.bmId}
                                </div>
                            </Row>
                        )}
                        {data.bmCode != null && (
                            <Row>
                                {Translations[lang].bmCode}
                                <div>
                                    {data.bmCode}
                                </div>
                            </Row>
                        )}
                    </Receipt>

                    <Descr>
                        {Translations[lang].pleasePay}
                    </Descr>
                </Left>
                <Bar />
                <Right>
                    <Title>
                        {Translations[lang].summary}
                    </Title>

                    <Summary>
                        <Pricing>
                            {Translations[lang].charterPrice}
                            <div>
                                {formatNumberByCountry(data.totalPrice - data.securityDeposit - data.bundlePrice, lang, 2)} EUR
                            </div>
                        </Pricing>
                        <Pricing>
                            {Translations[lang].depositPrice}
                            <div>
                                {formatNumberByCountry(data.securityDeposit, lang, 2)} EUR
                            </div>
                        </Pricing>
                        <Pricing>
                            {Translations[lang].bundlePrice}
                            <div>
                                {formatNumberByCountry(data.bundlePrice, lang, 2)} EUR
                            </div>
                        </Pricing>
                        <Spacer />
                        {data.payments > 1 && (
                            <>
                                <Pricing>
                                    {Translations[lang].firstPaymentSummary}
                                    <div>
                                        {formatNumberByCountry(parseInt(data.totalPrice / 2), lang, 2)} EUR
                                    </div>
                                </Pricing>
                                <Pricing>
                                    {Translations[lang].secondPaymentSummary}
                                    <div>
                                        {formatNumberByCountry(parseInt(data.totalPrice / 2 + (data.totalPrice & 1)), lang, 2)} EUR
                                    </div>
                                </Pricing>
                            </>
                        )}
                        <Pricing>
                            {Translations[lang].sumup}
                            <div>
                                {formatNumberByCountry(data.totalPrice, lang, 2)} EUR
                            </div>
                        </Pricing>
                    </Summary>

                    <Descr>
                        {Translations[lang].payOnline}
                    </Descr>

                    <Buttons>
                        {data.payments == 1 ? (
                            <Button disabled={data.status !== __booking_status.AWAITING_PAYMENT} onClick={onPaymentRequest}>
                                {Translations[lang].fullPayment}
                            </Button>
                        ) : (
                            <>
                                <Button disabled={data.status !== __booking_status.AWAITING_PAYMENT} onClick={onPaymentRequest}>
                                    {Translations[lang].firstPayment}
                                </Button>
                                <Button disabled={data.status !== __booking_status.AWAITING_SECOND_PAYMENT} onClick={onPaymentRequest}>
                                    {Translations[lang].finalPayment}
                                </Button>
                            </>
                        )}
                        <Button onClick={onContractDownload}>
                            {Translations[lang].downloadContract}
                        </Button>
                    </Buttons>
                </Right>
            </Wrapper>
            <Footer />
        </>
    )
}