import { useContext } from "react";
import Footer from "../../Components/Footer/Footer";
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import { Box, Button, Descr, Title, Wrapper } from "./Common";
import { LangContext } from "../../App";
import { Translations } from "./Lang";
import { useParams } from "react-router-dom";

export default function SuccessfullPaymentPage ()
{
    const { lang } = useContext(LangContext);
    const { bookingId } = useParams();

    return (
        <>
            <LiteMenu />
            <Wrapper>
                <Box>
                    <Title>
                        {Translations[lang].success}
                    </Title>
                    <Descr>
                        {Translations[lang].successDescr}
                    </Descr>
                    <Button href={`/rezerwacja/${bookingId}`}>
                        {Translations[lang].detailsButton}
                    </Button>
                </Box>
            </Wrapper>
            <Footer />
        </>
    )
}