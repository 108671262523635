
export const Translations = 
{
  pl: 
  {
    stripeBrands: [
      "Czarter jachtów",
      "Wyjazdy firmowe",
      "Szkolenia",
      "Wyjazdy indywidualne"
    ],
    bundleDescription: {
      standard: <>Zawiera podstawowe usługi przewoźnika <br/></>,
      comfort: <>Zawiera to co pakiet standard oraz: <br/></>,
      prestige: <>Zawiera to co pakiet comfort oraz: <br/></>,
      all_inclusive: <>Zawiera to co pakiet prestige oraz: <br/></>, 
    },
    contact: 'Kontakt',
    // callUs: 'Zadzwoń do nas:',
    andMore: 'i więcej',
    slCountry: 'Kraj',
    slBase: 'Port',
    slWc: 'Liczba łazienek',
    slKind: 'Typ jednostki',
    slLength: 'Długość (m)',
    slModel: "Model",
    slCompany: "Armator",
    spModel: "Wybierz model jachtu",
    spCompany: "Wybierz armatora",
    slHeads: 'Liczba osób',
    slDate: 'Czarter od',
    slDays: 'Liczba dni',
    slCabs: 'Liczba kabin',
    slBundles: 'Pakiet',
    slYachtType: 'Rodzaj jachtu',
    slYear: "Rok produkcji jachtu",
    search: 'WYSZUKAJ',
    spCountry: 'Wybierz kraj',
    spYachtType: "Wybierz rodz..", /** sory ale tyle kurwa razy zmieniane ze ja pierdole zachowac konsystencje */
    spYachtTypes: [
      {label: "Jacht żaglowy", value: "sailboat"},
      {label: "Katamaran", value: "catamaran"},
      {label: "Jacht motorowy", value: "motoryacht"},
      {label: "Katamaran motorowy", value: "powercatamaran"}
    ],
    spBase: 'Wybierz port',
    spDate: 'Wybierz termin',
    spChoose: "Wybierz",
    spBundle: "Wybierz",
    spTypes: {
      bareboat: 'bareboat', 
      crewed: 'crewed', 
      cabin: 'cabin', 
      flotilla: 'flotilla', 
      power: 'power', 
      berth: 'berth', 
      regatta: 'regatta'
    },
    spDays: {
      7: "7 dni",
      14: "14 dni",
      21: "21 dni",
      28: "28 dni"
    },
    spBundles: {
      standard: "Standard",
      comfort: "Comfort",
      prestige: "Prestige",
      all_inclusive: "All Inclusive"
    },
    spBundlesStandardOnly: {
      standard: "Standard",
    },
    spCabins: {
      1: "1 i więcej",
      2: "2 i więcej",
      3: "3 i więcej",
      4: "4 i więcej",
      5: "5 i więcej",
    },
    spHeads: {
      2: "2 i więcej",
      3: "3 i więcej",
      4: "4 i więcej",
      5: "5 i więcej",
      6: "6 i więcej",
      7: "7 i więcej",
      8: "8 i więcej",
      9: "9 i więcej",
      10: "10 i więcej",
      11: "11 i więcej",
      12: "12 i więcej",
    }
  }
}

Translations.en = Translations.pl;
Translations.de = Translations.pl;
