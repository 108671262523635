import { useContext, useEffect, useState } from "react";
import SelectRow from "./SelectRow";
import axios from "axios";
import { LangContext } from "../../../App";

import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import __server from "../../../Api";

export default function ModelAndCompanyInputs ()
{
  // const [options, setOptions] = useState([]);

  const [models, setModels] = useState([]);
  const [companies, setCompanies] = useState([]);

  const { lang } = useContext(LangContext);

  useEffect(() => {
    axios.get(`${__server}/front/searchables`).then((resp) => {
      setModels(resp.data.models);
      setCompanies(resp.data.companies);
    })
  }, []);


  return (
    <>
      <SelectRow
        name="model" 
        options={models} 
        label={MenuTranslations[lang].slModel} 
        placeholder={MenuTranslations[lang].spModel} 
        isClearable={true}
        isSearchable={true}
      />
      <SelectRow
        name="company" 
        options={companies} 
        label={MenuTranslations[lang].slCompany} 
        placeholder={MenuTranslations[lang].spCompany} 
        isClearable={true}
        isSearchable={true}
      />
    </>
  )
}
