import axios from "axios";
import __server from "../../Api";
import styled from "styled-components";
import Model from "../../Pages/Models/Model";
import { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";

const Wrapper = styled.div`
	margin-top: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 14px;

	@media (max-width: 767px) {
		grid-template-columns: 1fr;
		gap: 16px;
		
		> a:nth-child(n+3) {
			display: none;
		}
	}

`

const Loading = styled.div`
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function Models ()
{
  const [list, setList] = useState(null);

  useEffect(() => {

    axios.get(`${__server}/front/promoted-model`).then((resp) => {
      console.log(resp.data);
      setList(resp.data);
    }).catch((err) => {
      console.error(err);
    })

  }, []);

  if (list === null) {
    return (
      <Loading>
        <ScaleLoader color="#133b51"/>
      </Loading>
    );
  }

  return (
    <Wrapper>
      {list.map((item, key) => (
        <Model {...item.model} key={key} />
      ))}
    </Wrapper>
  )
}