import styled from "styled-components";

export const Wrapper = styled.div`
    width: 1320px;
    margin: auto;
    margin-top: 60px;

    @media (max-width: 757px) {
        width: 100%;
        margin-top: 35px;
        box-sizing: border-box;
        padding: 0px 15px;
    }
`

export const Box = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 45px 0px;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 757px) {
        padding: 50px 30px;
    }
`

export const Title = styled.div`
    font-family: 'Gilroy-ExtraBold';
    font-size: 24px;
    font-weight: 400;
    color: #133b51;
    text-align: center;
`

export const Descr = styled.div`
    font-family: 'Gilroy-Light';
    font-size: 20px;
    font-weight: 400;
    color: #133b51;
    text-align: center;
    width: 800px;

    @media (max-width: 757px) {
        width: 100%;
    }
`

export const Button = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gilroy-ExtraBold';
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    background-color: #133b51;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    padding: 14px;
`