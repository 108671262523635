
import { useContext } from "react"
import { Translations as MenuTranslations } from "../../../Components/Menu/Lang"
import { LangContext } from "../../../App"
import SelectRow from "./SelectRow";
import { convertTranslationToOptions } from "../../../utils/translation-to-options";
import Range from "./Range";
import RowInput from "./RowInput";
import { OffersFilterContext } from "../OfertyPage";

export default function YearInput ()
{
  const { lang } = useContext(LangContext);
  const { filters } = useContext(OffersFilterContext);

  return (
    <RowInput 
      label={MenuTranslations[lang].slYear}
      subtitle={`${filters.minYear}-${filters.maxYear}`}
      htInput={<Range min={1975} max={(new Date()).getFullYear()} name="Year" />}
    />
  )
}