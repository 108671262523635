import { useParams } from "react-router-dom"
import LiteMenu from "../../Components/Menu/Factory/LiteMenu";
import Footer from "../../Components/Footer/Footer";
import { useContext, useEffect } from "react";
import axios from "axios";
import __server from "../../Api";
import { Box, Descr, Title, Wrapper } from "./Common";
import { Translations } from "./Lang";
import { LangContext } from "../../App";
import { ScaleLoader } from "react-spinners";

export default function ConfirmationPage ()
{
    const { stripePaymentIntent, bookingId } = useParams();
    const { lang } = useContext(LangContext);

    useEffect(() => {
        setTimeout(() => {
            // 10x engineering
            axios.put(`${__server}/front/payment/${stripePaymentIntent}`).then((resp) => {
                console.log(resp);
                window.location.href = `/platnosc-pomyslna/${bookingId}`;
            }).catch((err) => {
                console.error(err);
                window.location.href = `/blad-platnosci/${bookingId}`;
            });
        }, 5000);
    }, []);

    return (
        <>
            <LiteMenu />
            <Wrapper>
                <Box>
                    <Title>
                        {Translations[lang].veryfication}
                    </Title>
                    <Descr>
                        {Translations[lang].pleaseWait}
                    </Descr>

                    <ScaleLoader color="#133b51" />
                </Box>
            </Wrapper>
            <Footer />
        </>
    )
}